@import "../../config/Variables";

.github-action-row {
  display: flex;
  justify-content: space-between;
}

.github-action-form {
  display: flex;
  gap: 12px;
}
