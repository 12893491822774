@import "../../config/Variables";

.file-editor-file-row {
  cursor: pointer;
}

.file-editor-file-row:hover {
  text-decoration: underline;
  color: #1677ff;
}

.file-editor-file-row:active {
  text-decoration: underline;
  color: #cfe8fc;
}

.cm-focused {
  outline: none !important;
}
